<template>
  <!-- Main content -->
  <section class="content">
    <!-- 搜索 -->
    <div class="btn-box">
      <el-form :inline="true">
        <el-form-item label="中转页id：">
            <el-input v-model="search_id" placeholder=""></el-input>
        </el-form-item>
        <el-form-item>
            <el-button type="primary" @click="seach(1)">搜索</el-button>
            <!-- <el-button type="" @click="reset(1)">重置</el-button> -->
        </el-form-item>
    </el-form>
    </div>
    <!-- 添加按钮 -->
    <div class="btn-box">
      <el-button type="primary" size="mini" @click="add">添加中转页</el-button>
    </div>
    <!-- 表单 -->
    <el-table 
        v-loading="loading"
        :data="transferList"
        border
        style="width: 100%;">
        <el-table-column fixed label="编号" prop="id" width="50"></el-table-column>
        <el-table-column fixed label="页面标题" prop="title" width="250"></el-table-column>
        <el-table-column  label="页面背景" prop="background_img" width="80">
            <template slot-scope="item">
                <el-image :src="item.row.background_img" :preview-src-list="[item.row.background_img]"></el-image>
            </template>
</el-table-column>
<el-table-column label="中间文案" prop="content"></el-table-column>
<el-table-column label="安卓跳转链接" prop="android_redirect_url">
    <template slot-scope="item">
        {{item.row.android_redirect_url}}
        <el-button type="text" size="small" v-clipboard:copy=item.row.android_redirect_url v-clipboard:success="copy" >复制</el-button>
    </template>
</el-table-column>
<el-table-column label="跳转类型" prop="kl">
    <template slot-scope="item">
        {{item.row.redirect_type | type}}
    </template>
</el-table-column>
<el-table-column label="页面标识" prop="page_id"></el-table-column>
<el-table-column fixed="right" label="操作">
    <template slot-scope="item">
            <el-button type="primary" size="mini" @click="getTransferData(item.row.id)">修改</el-button>
        </template>
</el-table-column>
</el-table>
<!-- 分页 -->
<el-pagination background layout="total, sizes,prev, pager, next,jumper" :total="total" @size-change="sizeChange" @current-change="currentChange" :current-page="page">
</el-pagination>
<!-- 修改添加弹窗 -->
<el-dialog title="添加/修改" :visible.sync="dialog" :close-on-click-modal=false>
    <el-form>
        <el-form-item label="页面标题">
            <el-input v-model="title" placeholder=""></el-input>
        </el-form-item>
        <el-form-item label="页面背景图">
            <upLoadText @url="imgUrl" id="up" v-model="background_img"></upLoadText>
        </el-form-item>
        <el-form-item label="中间文案颜色">
            <el-input v-model="content_color" placeholder=""></el-input>
        </el-form-item>
        <el-form-item label="中间文案">
            <el-input v-model="content" placeholder=""></el-input>
        </el-form-item>
        <el-form-item label="左侧按钮">
            <upLoadText @url="LimgUrl" id="left" v-model="left_button_img"></upLoadText>
        </el-form-item>
        <el-form-item label="中间按钮">
            <upLoadText @url="CimgUrl" id="content" v-model="center_button_img"></upLoadText>
        </el-form-item>
        <el-form-item label="右侧按钮">
            <upLoadText @url="RimgUrl" id="right" v-model="right_button_img"></upLoadText>
        </el-form-item>
        <el-form-item label="携带参数">
            <el-input v-model="bind_params" placeholder=""></el-input>
        </el-form-item>
        <el-form-item label="安卓跳转链接">
            <el-input v-model="android_redirect_url" placeholder=""></el-input>
        </el-form-item>
        <el-form-item label="IOS跳转链接">
            <el-input v-model="ios_redirect_url" placeholder=""></el-input>
        </el-form-item>
        <el-form-item label="中间文案背景颜色">
            <el-input v-model="content_background_color" placeholder=""></el-input>
        </el-form-item>
        <el-form-item label="背景填充色">
            <el-input v-model="background_fill_color" placeholder=""></el-input>
        </el-form-item>
        <el-form-item label="跳转类型">
            <el-select v-model="redirect_type" placeholder="">
                <el-option v-for="item in classifyList" :label="item.name" :value="item.type"></el-option>
            </el-select>
        </el-form-item>
        <el-form-item label="pid">
            <el-input v-model="pid" placeholder=""></el-input>
        </el-form-item>
        <el-form-item label="跳转类型">
            <el-select v-model="platform" placeholder="">
                <el-option v-for="item in platformList" :label="item.name" :value="item.type"></el-option>
            </el-select>
        </el-form-item>

    </el-form>
    <div slot="footer" class="dialog-footer">
        <el-button @click="dialog = false">取 消</el-button>
        <el-button type="primary" @click="alter">确 定</el-button>
    </div>
</el-dialog>
</section>
</template>
<script>
    let common = JSON.parse(localStorage.getItem('common'))
    import * as api from "@/config/api";
    export default {
        name: 'transfer',
        data() {
            return {
                loading: false,
                pagesize: 10,
                page: 1,
                total: 0,

                classifyList: common.redirect_type_list,
                platformList: common.platform_list,

                dialog: false,
                transferList: [],

                search_id: '',


                id: '',
                title: '',
                background_img: '',
                content_color: '#333',
                content: '',
                center_button_img: 'http://imgxmx.xiaomanxiong.com/admin/202005/202005301702220190569.gif',
                left_button_img: 'http://imgxmx.xiaomanxiong.com/admin/202102/202102190938230197641.png',
                right_button_img: 'http://imgxmx.xiaomanxiong.com/admin/202102/202102190938330112207.png',
                bind_params: '',
                android_redirect_url: '',
                ios_redirect_url: '',
                content_background_color: '#fff',
                background_fill_color: '',
                redirect_type: '',
                pid: '',
                platform: '',
            }
        },
        filters: {
            type(val) {
                let name = ''
                if (JSON.stringify(common.redirect_type_list).indexOf(val) != -1) {
                    common.redirect_type_list.map((a, index) => {
                        if (val === a.type) {
                            name = a.name
                        }
                    })
                }
                return name
            }
        },
        mounted() {
            this.getTransferList()
        },
        methods: {
            // 获取列表
            getTransferList(page, size) {
                api.getTransferList({
                    page: page ? page : this.page,
                    pagesize: size ? size : this.pagesize,
                    page_id: this.search_id
                }, res => {
                    this.transferList = res.data.list
                    this.total = Number(res.data.count)
                })
            },
            // 分页点击
            sizeChange(val) {
                this.pagesize = val
                this.getTransferList()
            },
            currentChange(val) {
                this.page = val
                this.getTransferList()
            },
            //搜索
            seach(page) {
                this.page = page
                this.getTransferList(page)
            },
            reset(page) {
                this.search_id = ''
                this.page = page
                this.getTransferList(page)
            },
            // 复制
            copy() {
                this.$message({
                    message: '复制成功',
                    type: 'success'
                })
            },
            // 上传图片
            imgUrl(val) {
                this.background_img = val
            },
            LimgUrl(val) {
                this.left_button_img = val
            },
            CimgUrl(val) {
                this.center_button_img = val
            },
            RimgUrl(val) {
                this.right_button_img = val
            },
            // 获取详情
            getTransferData(id) {
                this.id = id
                api.getTransferData({
                    id: id
                }, res => {
                    this.title = res.data.title
                    this.background_img = res.data.background_img
                    this.content_color = res.data.content_color
                    this.content = res.data.content
                    this.center_button_img = res.data.center_button_img
                    this.left_button_img = res.data.left_button_img
                    this.right_button_img = res.data.right_button_img
                    this.bind_params = res.data.bind_params
                    this.android_redirect_url = res.data.android_redirect_url
                    this.ios_redirect_url = res.data.ios_redirect_url
                    this.content_background_color = res.data.content_background_color
                    this.background_fill_color = res.data.background_fill_color
                    this.redirect_type = res.data.redirect_type
                    this.pid = res.data.pid
                    this.platform = res.data.platform
                    this.dialog = true
                })
            },
            // 添加选品
            add() {
                this.id = ''
                this.title = ''
                this.background_img = ''
                this.content_color = '#333'
                this.content = ''
                this.center_button_img = 'http://imgxmx.xiaomanxiong.com/admin/202005/202005301702220190569.gif'
                this.left_button_img = 'http://imgxmx.xiaomanxiong.com/admin/202102/202102190938230197641.png'
                this.right_button_img = 'http://imgxmx.xiaomanxiong.com/admin/202102/202102190938330112207.png'
                this.bind_params = ''
                this.android_redirect_url = ''
                this.ios_redirect_url = ''
                this.content_background_color = '#fff'
                this.background_fill_color = ''
                this.redirect_type = ''
                this.pid = ''
                this.platform = ''
                this.dialog = true
            },
            // 修改、添加频道分类
            alter() {
                if (this.id != '') {
                    api.updateTransfer({
                        id: this.id,
                        title: this.title,
                        background_img: this.background_img,
                        content_color: this.content_color,
                        content: this.content,
                        center_button_img: this.center_button_img,
                        left_button_img: this.left_button_img,
                        right_button_img: this.right_button_img,
                        bind_params: this.bind_params,
                        android_redirect_url: this.android_redirect_url,
                        ios_redirect_url: this.ios_redirect_url,
                        content_background_color: this.content_background_color,
                        background_fill_color: this.background_fill_color,
                        redirect_type: this.redirect_type,
                        pid: this.pid,
                        platform: this.platform,
                    }, res => {
                        this.$message({
                            message: res.msg,
                            type: 'success'
                        })
                        this.dialog = false
                        this.getTransferList()
                    })
                    return
                }
                api.addTransfer({
                    title: this.title,
                    background_img: this.background_img,
                    content_color: this.content_color,
                    content: this.content,
                    center_button_img: this.center_button_img,
                    left_button_img: this.left_button_img,
                    right_button_img: this.right_button_img,
                    bind_params: this.bind_params,
                    android_redirect_url: this.android_redirect_url,
                    ios_redirect_url: this.ios_redirect_url,
                    content_background_color: this.content_background_color,
                    background_fill_color: this.background_fill_color,
                    redirect_type: this.redirect_type,
                    pid: this.pid,
                    platform: this.platform,
                }, res => {
                    this.$message({
                        message: res.msg,
                        type: 'success'
                    })
                    this.dialog = false
                    this.getTransferList()
                })
            },
            // 上下架
            delTransfer(id) {
                api.delTransfer({
                    id: id,
                }, res => {
                    this.$message({
                        type: 'success',
                        message: res.msg
                    })
                    this.getTransferList()
                })
            },
        }
    }
</script>
<style lang="scss">
    .btn-box {
        text-align: left;
        margin-bottom: 20px;
    }
    
    .el-image img {
        height: 40px;
    }
    
    .el-image-viewer__canvas img {
        width: auto;
        height: auto;
    }
</style>